<template>
    <div>
        <div class="content" :style="cur==index?{background:line,width:widths}:''" @click="cilckMethod">
            <div class="title" :style="{width:widths}">{{item.dictLabel}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        cur:{
            type:Number,
            default:0
        },
        index:{
            type:Number,
            default:0
        },
        widths:{
            type:String,
            default:'144px'
        }
    },
	created(){
		if (this.cur == 0) {
			this.$emit('cilckMethod',this.cur,this.item.dictCode);
		}
	},
    computed:{
        line(){
            return this.$store.state.isZSkin
        },
    },
    methods:{
      cilckMethod(){
        this.$emit('cilckMethod',this.cur,this.item.dictCode);
      }
    },
}
</script>

<style scoped lang="scss">
.content{
    margin-top: 10px;
    cursor: pointer;
    border-radius: 40px;
}
.title{
    text-align: center;
    height: 41px;
    line-height: 41px;
    font-size: 15px;
    color: #FFFFFF;
}
</style>