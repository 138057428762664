<template>
  <div class="mian-view">
      <div class="content-view">
          <!-- 头 -->
          <div class="quality-top" :style="{backgroundColor:isSkin}">
            <div class="thumb">
                <img src="../../../assets/home/quality.png" alt="">
            </div>
            <div class="thumb-title">精品图书</div>
            <div class="sorting">排序显示</div>
            <div class="select">
                <el-select v-model="value" placeholder="请选择" @change="changeMethod">
                <el-option v-for="(item,index) in selectList" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
          </div>
          <!-- 内容 -->
          <div class="content-list">
              <div class="left-menu" :style="{backgroundColor:isSkin}">
                  <leftMenu v-for="(item,index) in qualityItem" :key="index" :widths="'243px'" :index="cur" :cur="index" :item="item" @cilckMethod="cilckMethod"></leftMenu>
              </div>
              <div class="right-view">
                  <bookList @click="bookMethod()" v-for="(item,index) in List" :key="index" :index="index" :item="item"></bookList>
                  <div class="page" v-show="pageShow">
                     <div class="page1">
                        <el-pagination background :current-page="currentPage"
                        :page-size="12" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                        </el-pagination>
                     </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import leftMenu from '../../../components/tools/leftMenu-box'
import bookList from '../components/newBook-box'
export default {
    components:{leftMenu,bookList},
    data() {
        return {
            pageShow:false,
            totals:1,
            currentPage:1,
            pageSize:1,
            cur:0,
            value: '',
			code:'',
            selectList:[{
                value: '',
                label: '默认'
            },{
                value: '1',
                label: '按点击量'
            },{
                value: '2',
                label: '按收藏量'
            }],
            qualityItem:[],
            List:[],
        }
    },
    created(){
        this.$store.commit('changClick',1)
        var skin = sessionStorage.getItem('Skin')
        var zskin = sessionStorage.getItem('ZSkin')
        if (skin) {
            this.$store.commit('changSkin',skin)
        }
        if (zskin) {
            this.$store.commit('changZSkin',zskin)
        }
		this.getBoutiqueCategory()
        this.currentMethod()
        this.handleCurrentChange(1)
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },
        line(){
            return this.$store.state.isZSkin
        },
    },
    methods:{
		/**精品图书分类**/
		getBoutiqueCategory(){
			var that = this
			that.$api.getBoutiqueCategory({}).then(res=>{
				if (res.data.code == 0) {
					that.qualityItem = res.data.data
				}else{
					that.qualityItem = []
				}
			})
		},
		/**获取精品图书**/
		getQualityBooks(){
			var that = this
			that.$api.getQualityBooks({
				params:{
					dictCode:this.code,
					pageNum:that.currentPage,
					pageSize:12,
					sort:that.value
				},
			}).then(res=>{
				if (res.data.code == 0) {
					that.List = res.data.data.list
					that.totals = res.data.data.total
					if (res.data.data.list.length == 0){
						that.pageShow = false
					}else {
						that.pageShow = true
					}
				}else{
					that.List = []
                    that.pageShow = false
				}
			})
		},
        /**下拉框选择回调**/
        changeMethod(index){
			this.value = index
			this.getQualityBooks()
        },
        currentMethod(){
            this.$nextTick(function () {
                /**更换下拉选择框皮肤**/
                var array = document.querySelectorAll(".el-input__inner");
                if (array && array.length>0) {
                    array[0].style.backgroundColor  = this.line;
                    array[0].style.border  = this.line;
                }
                var array1 = document.querySelectorAll(".el-select .el-input.is-focus .el-input__inner");
                if (array1 && array1.length>0) {
                    array1[0].style.borderColor  = this.line;
                }
                var array2 = document.querySelectorAll(".el-select .el-input__inner:focus");
                if (array2 && array2.length>0) {
                    array2[0].style.borderColor  = this.line;
                }
            })
        },
        /**图书菜单点击按钮**/
        cilckMethod(index,code){
            this.cur = index;
            this.code = code;
            this.currentPage = 1
            this.totals = 1
            this.getQualityBooks()
        },
        /**当前页按钮**/
        handleCurrentChange(index) {
            this.currentPage = index;
			this.getQualityBooks()
            this.$nextTick(function () {
                /**更换分页按钮皮肤**/
                var array = document.querySelectorAll(".el-pagination.is-background .el-pager li");
                if(array && array.length>0){
                array.forEach((item,index1) => {
                    if (index == array[index1].innerText) {
                    array[index1].style.background  = this.isSkin;
                    }else{
                    array[index1].style.background  = "#f4f4f5";
                    }
                })
                }
            });
        },
    }
}
</script>

<style scoped lang="scss">
.mian-view{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-view{
        width: 1200px;
        margin-top: 50px;
        .quality-top{
            width: 100%;
            height: 55px;
            background: #CCA2A2;
            border-radius: 4px;
            display: flex;
            justify-items: center;
            align-items: center;
            position: relative;
            .thumb{
                margin-left: 15px;
                width: 15px;
                height: 15px;
                display: flex;
                justify-items: center;
                align-items: center;
            }
            .thumb-title{
                font-size: 18px;
                line-height: 55px;
                color: #FFFFFF;
                margin-left: 10px;
            }
            .select{
                position: absolute;
                right: 12px;
            }
            .sorting{
                font-size: 13px;
                line-height: 18px;
                color: rgba(255, 255, 255, 0.9);
                position: absolute;
                right: 136px;
                font-weight: normal;
                cursor: pointer;
            }
        }
        .content-list{
            width: 100%;
            display: flex;
            justify-items: center;
            align-items: flex-start;
            .left-menu{
                width: 285px;
                margin-top: 20px;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 20px;
				margin-bottom: 20px;
                padding-top: 10px;
            }
            .right-view{
                width: 895px;
                display: flex;
                display: -webkit-flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                margin-left: 20px;
                padding-top: 20px;
                .page{
                    width: 896px;
                    padding: 20px 20px 100px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                .page1{
                    display: flex;
                    justify-items: center;
                    align-items: center;
                }
                }
            }
        }
    }
}
</style>
